import { render, staticRenderFns } from "./modal.vue?vue&type=template&id=6c546e82"
import script from "./modal.vue?vue&type=script&lang=ts"
export * from "./modal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuCard: require('/mnt/vss/_work/1/s/components/nu/card.vue').default})
