import { GraphQLClient } from 'graphql-request'

import { getSdk as accountsPublicSdk } from './types/accounts-public'
import { getSdk as nuagPublicSdk } from './types/nuag-public'
import { getSdk as accountsBorneSdk } from './types/accounts-borne'
import { getSdk as nuagBorneSdk } from './types/nuag-borne'
import { getSdk as accountsPrivateSdk } from '~/graphql/types/accounts'
import { getSdk as nuagPrivateSdk } from '~/graphql/types/nuag'
import { authenticatedFetch } from '~/lib/zerauth'
import { authenticatedFetch as straightAuthenticatedFetch } from '~/lib/straightauth'

const ACCOUNTS_PRIVATE_URI = process.env.ACCOUNTS_URI! + '/private'
const ACCOUNTS_BORNE_URI = process.env.ACCOUNTS_URI! + '/borne'
const ACCOUNTS_PUBLIC_URI = process.env.ACCOUNTS_URI! + '/public'

const NUAG_PRIVATE_URI = process.env.API_URI! + '/private'
const NUAG_BORNE_URI = process.env.API_URI! + '/borne'
const NUAG_PUBLIC_URI = process.env.API_URI! + '/public'

// Accounts Public - Graph Client
export const accountsPublicClient = accountsPublicSdk(new GraphQLClient(ACCOUNTS_PUBLIC_URI))

export const accountsBornePublicClient = accountsBorneSdk(new GraphQLClient(ACCOUNTS_BORNE_URI))

export const accountsBorneClient = accountsBorneSdk(
  new GraphQLClient(ACCOUNTS_BORNE_URI, {
    fetch: straightAuthenticatedFetch,
  })
)

export const accountsPrivateClient = accountsPrivateSdk(
  new GraphQLClient(ACCOUNTS_PRIVATE_URI, {
    credentials: 'include',
    fetch: authenticatedFetch,
  })
)

export const nuagPublicClient = nuagPublicSdk(new GraphQLClient(NUAG_PUBLIC_URI))

export const nuagBornePublicClient = nuagBorneSdk(new GraphQLClient(NUAG_BORNE_URI))

export const nuagBorneClient = nuagBorneSdk(
  new GraphQLClient(NUAG_BORNE_URI, {
    fetch: straightAuthenticatedFetch,
  })
)

export const nuagPrivateClient = nuagPrivateSdk(
  new GraphQLClient(NUAG_PRIVATE_URI, {
    credentials: 'include',
    fetch: authenticatedFetch,
  })
)
