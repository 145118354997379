import {
  AlgorithmVersion,
  type IStraightAuthAlgorithm,
} from '~/lib/straightauth/cryptography/types'

import { straightAuthV1 } from '~/lib/straightauth/cryptography/algorithms/v1'

export function getAlgorithm(version: AlgorithmVersion): IStraightAuthAlgorithm {
  if (version === AlgorithmVersion.V1) return straightAuthV1
  throw new Error('Invalid Algorithm Version')
}
