import { render, staticRenderFns } from "./sidebar-button.vue?vue&type=template&id=e929bcd6"
import script from "./sidebar-button.vue?vue&type=script&lang=ts"
export * from "./sidebar-button.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FaIcon: require('/mnt/vss/_work/1/s/components/fa-icon.vue').default,NuCt: require('/mnt/vss/_work/1/s/components/nu/ct.vue').default})
