import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.timers.js";
/** Various utils about Vue **/
import Vue, { watch } from 'vue';

// Type-safe version of Vue.set()
export function reactiveSet(object, key, value) {
  Vue.set(object, key, value);
}
export function reactiveSetArray(array, key, value) {
  Vue.set(array, key, value);
}
export function waitFor(effect) {
  return new Promise(function (resolve) {
    var stopWatcher = watch(effect, function (v) {
      if (v) {
        if (stopWatcher) {
          stopWatcher();
        }
        resolve();
      }
    }, {
      immediate: true
    });
  });
}
export function debouncedOnClearRef(value) {
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
  var timeout = null;
  return customRef(function (track, trigger) {
    return {
      get: function get() {
        track();
        return value;
      },
      set: function set(newValue) {
        if (newValue !== null) {
          value = newValue;
          trigger();
          return;
        }
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(function () {
          value = null;
          trigger();
        }, delay);
      }
    };
  });
}
export function debouncedOnClearComputed(cb) {
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
  return customRef(function (track, trigger) {
    var timeout = null;
    var value = null;
    watch(cb, function (newValue) {
      if (newValue !== null) {
        value = newValue;
        trigger();
        return;
      }
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(function () {
        value = null;
        trigger();
      }, delay);
    }, {
      immediate: true,
      deep: true
    });
    return {
      get: function get() {
        track();
        return value;
      },
      set: function set() {}
    };
  });
}