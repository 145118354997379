import { encode as bufferToB64, decode as b64ToBuffer } from 'base64-arraybuffer'

import {
  AlgorithmVersion,
  type ExportedPublicKey,
  type IPrivateKey,
  type IPublicKey,
  type IStraightAuthAlgorithm,
  type SignedPayload,
} from '../types'

type Exported = { v: AlgorithmVersion; p: CryptoKey; pr: CryptoKey }

const subtleCrypto = window.crypto.subtle

class PublicKeyV1 implements IPublicKey {
  private readonly _key: CryptoKey

  constructor(publicKey: CryptoKey) {
    this._key = publicKey
  }

  async export(): Promise<ExportedPublicKey> {
    const buffer = await subtleCrypto.exportKey('spki', this._key)

    return { algorithmVersion: AlgorithmVersion.V1, publicKey: bufferToB64(buffer) }
  }
}

class PrivateKeyV1 implements IPrivateKey {
  private readonly _keyPair: CryptoKeyPair

  constructor(key: CryptoKeyPair) {
    this._keyPair = key
  }

  get publicKey(): PublicKeyV1 {
    return new PublicKeyV1(this._keyPair.publicKey)
  }

  // FIXMe: this takes b64 data. Make a version that takes arbitrary stuff later
  async sign(payload: ArrayBuffer): Promise<SignedPayload> {
    const signature = await subtleCrypto.sign(
      { name: 'ECDSA', hash: 'SHA-256' },
      this._keyPair.privateKey,
      payload
    )

    return {
      payload: bufferToB64(payload),
      signature: { algorithmVersion: AlgorithmVersion.V1, data: bufferToB64(signature) },
    }
  }

  toSerializable(): Exported {
    return { v: AlgorithmVersion.V1, p: this._keyPair.publicKey, pr: this._keyPair.privateKey }
  }

  static importFromSerializable(value: unknown): IPrivateKey | null {
    if (!value) return null
    if (typeof value !== 'object') return null
    if (!('v' in value && 'p' in value && 'pr' in value)) return null
    if (value.v !== AlgorithmVersion.V1) return null
    if (!(value.p instanceof CryptoKey && value.pr instanceof CryptoKey)) return null

    const publicKey = value.p
    const privateKey = value.pr

    if (publicKey.type !== 'public') throw new Error('Expected public key')
    if (privateKey.type !== 'private') throw new Error('Expected private key')

    if (privateKey.algorithm.name !== 'ECDSA') throw new Error('Expected ECDSA private key')
    if (!('namedCurve' in privateKey.algorithm && privateKey.algorithm.namedCurve === 'P-256'))
      throw new Error('Invalid curve for v1')

    if (publicKey.algorithm.name !== 'ECDSA') throw new Error('Expected ECDSA public key')
    if (!('namedCurve' in publicKey.algorithm && publicKey.algorithm.namedCurve === 'P-256'))
      throw new Error('Invalid curve for v1')

    // TODO: more checks?

    return new PrivateKeyV1({ publicKey, privateKey })
  }
}

export const straightAuthV1: IStraightAuthAlgorithm = {
  async generatePrivateKey(): Promise<IPrivateKey> {
    const key = await subtleCrypto.generateKey({ name: 'ECDSA', namedCurve: 'P-256' }, false, [
      'sign',
    ])

    return new PrivateKeyV1(key)
  },

  importPrivateKeyFromSerializable(value: unknown): IPrivateKey {
    const result = PrivateKeyV1.importFromSerializable(value)
    if (!result) throw new Error('Invalid serialized private key')

    return result
  },
}
