import { render, staticRenderFns } from "./app-container.vue?vue&type=template&id=6dcda322"
import script from "./app-container.vue?vue&type=script&lang=ts"
export * from "./app-container.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./app-container.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuHyperlink: require('/mnt/vss/_work/1/s/components/nu/hyperlink.vue').default,NuLabel: require('/mnt/vss/_work/1/s/components/nu/label.vue').default,NuAlert: require('/mnt/vss/_work/1/s/components/nu/alert.vue').default})
