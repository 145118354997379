import type { ActiveSession, ILoggedInStraightAuth } from '~/lib/straightauth/logged-in'
import type { Result } from '~/graphql/hotchoco'
import { AlgorithmVersion, type OpenSessionFromBorneError } from '~/graphql/types/accounts-borne'
import { getAlgorithm } from '~/lib/straightauth/cryptography'
import { accountsStraightAuthClient } from '~/lib/straightauth/graph'

export interface ILoggedOutStraightAuth {
  readonly loggedIn: false
  loginFromTabletCode(
    code: string
  ): Promise<Result<ILoggedInStraightAuth, OpenSessionFromBorneError>>
}

export async function openTabletSession(
  code: string
): Promise<Result<ActiveSession, OpenSessionFromBorneError>> {
  const privateKey = await getAlgorithm(AlgorithmVersion.V1).generatePrivateKey()
  const publicKey = await privateKey.publicKey.export()

  const result = await accountsStraightAuthClient.openTabletSession({ code, publicKey })

  if (result.openSessionFromBorne.unauthenticatedAssemblySessionInfo) {
    const details = result.openSessionFromBorne.unauthenticatedAssemblySessionInfo

    return {
      sessionId: details.sessionId,
      nextChallengePayload: details.firstChallenge,
      privateKey,
    }
  }

  return { error: result.openSessionFromBorne.errors![0]! as OpenSessionFromBorneError }
}
