import { render, staticRenderFns } from "./default-v3.vue?vue&type=template&id=27b74fc3"
import script from "./default-v3.vue?vue&type=script&setup=true&lang=ts"
export * from "./default-v3.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./default-v3.vue?vue&type=style&index=0&id=27b74fc3&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./default-v3.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonTopBar: require('/mnt/vss/_work/1/s/components/common/top-bar.vue').default,NuAvatar: require('/mnt/vss/_work/1/s/components/nu/avatar.vue').default,CommonSidebarButton: require('/mnt/vss/_work/1/s/components/common/sidebar-button.vue').default,CommonSidebarSection: require('/mnt/vss/_work/1/s/components/common/sidebar-section.vue').default,CommonSidebar: require('/mnt/vss/_work/1/s/components/common/sidebar.vue').default,CommonNotifications: require('/mnt/vss/_work/1/s/components/common/notifications.vue').default,CommonModalContainer: require('/mnt/vss/_work/1/s/components/common/modal-container.vue').default})
