import type { Context } from '@nuxt/types'
import { logWarningWithException } from 'nuag-core-utils/logging'
import { getInitializedZerauth, getZerauth, type IZerauth, sessionInfo } from '~/lib/zerauth'
import { getInitializedStraightAuth, getStraightAuth, type IStraightAuth } from '~/lib/straightauth'

export default function (context: Context) {
  const zerauth = getZerauth()
  const straightAuth = getStraightAuth()
  if (zerauth && straightAuth) {
    return handleRedirects(context, zerauth, straightAuth)
  }

  return initialize()
    .then(([zerauth, straightAuth]) => handleRedirects(context, zerauth, straightAuth))
    .catch((ex: Error) => {
      logWarningWithException('Zerauth', ex, 'Failed to initialize Zerauth')
      context.error({
        statusCode: 1998,
      })
    })
}

function extractFlag(context: Context, flag: string): boolean {
  if (!Array.isArray(context.route.meta)) {
    return false
  }
  return context.route.meta.some((m) => m && m[flag])
}

function handleRedirects(context: Context, zerauth: IZerauth, straightAuth: IStraightAuth) {
  const tablet = extractFlag(context, 'tablet')
  if (tablet) {
    // Bunch of special cases, remove when auth is properly done
    switch (context.route.name) {
      case 'tablet-id':
      case 'tablet-id-welcome':
      case 'tablet-id-tablet-code':
        if (straightAuth.loggedIn && straightAuth.sessionInfo.isAuthenticated)
          context.redirect({ name: 'tablet-id-vote', params: context.route.params })
        break

      case 'tablet-id-vote':
        if (!straightAuth.loggedIn || !straightAuth.sessionInfo.isAuthenticated)
          context.redirect({ name: 'tablet-id', params: context.route.params })
    }

    return
  }

  const ignoreAuth = extractFlag(context, 'ignoreAuth')
  if (ignoreAuth) {
    return
  }

  const isPublic = extractFlag(context, 'public')

  if (isPublic && zerauth.loggedIn) {
    context.redirect({
      name: sessionInfo.me!.organizations.length > 0 ? 'organizer' : 'participant',
    })
  } else if (!isPublic && !zerauth.loggedIn) {
    context.redirect({ name: 'welcome' })
  }
}

async function initialize() {
  const pbFull = document.getElementById('pb-full')

  if (pbFull) pbFull.style.width = '50%'

  try {
    return await Promise.all([getInitializedZerauth(), getInitializedStraightAuth()])
  } finally {
    const loadingContainer = document.getElementById('initial-loading')

    if (pbFull) pbFull.style.width = '100%'
    if (loadingContainer) loadingContainer.style.opacity = '0'
    await new Promise((resolve) => setTimeout(resolve, 200))
  }
}
