// From https://stackoverflow.com/a/61412099
export type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U]
export type ExcludeEmpty<T> = T extends AtLeastOne<T> ? T : never

// From https://stackoverflow.com/a/60132060
export const arrayOfAll =
  <T>() =>
  <U extends T[]>(array: U & ([T] extends [U[number]] ? unknown : 'Missing value')) =>
    array

export type DeepReplace<T, Old, New> = T extends object
  ? {
      [P in keyof T]: DeepReplace<T[P], Old, New>
    }
  : T extends Old
    ? Exclude<T, Old> | New
    : T
