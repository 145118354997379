import { render, staticRenderFns } from "./modal-container.vue?vue&type=template&id=f718d77a"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonModalGuardContainer: require('/mnt/vss/_work/1/s/components/common/modal-guard-container.vue').default})
