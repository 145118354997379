import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UUID: any;
};

export enum AlgorithmVersion {
  V1 = 'V1'
}

export type BasicStakeholderInfo = {
  __typename?: 'BasicStakeholderInfo';
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ChallengeExpiredError = Error & {
  __typename?: 'ChallengeExpiredError';
  message: Scalars['String'];
};

export type ChallengeSent = {
  __typename?: 'ChallengeSent';
  remainingAttempts: Scalars['Int'];
};

export type CheckChallengeCodeError = ChallengeExpiredError | FactorAlreadyValidatedError | InvalidCodeError | InvalidSession | NoChallengeError | TooManyAttemptsError;

export type CheckChallengeCodeInput = {
  code: Scalars['String'];
  factorType: FactorType;
};

export type CheckChallengeCodePayload = {
  __typename?: 'CheckChallengeCodePayload';
  boolean?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<CheckChallengeCodeError>>;
};

export type Error = {
  message: Scalars['String'];
};

export type FactorAlreadyValidatedError = Error & {
  __typename?: 'FactorAlreadyValidatedError';
  message: Scalars['String'];
};

export enum FactorType {
  Attendance = 'ATTENDANCE',
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type FactorUnavailableError = Error & {
  __typename?: 'FactorUnavailableError';
  message: Scalars['String'];
};

export type InvalidChallengeError = Error & {
  __typename?: 'InvalidChallengeError';
  message: Scalars['String'];
};

export type InvalidCodeError = Error & {
  __typename?: 'InvalidCodeError';
  message: Scalars['String'];
  remainingAttempts?: Maybe<Scalars['Int']>;
};

export type InvalidSession = Error & {
  __typename?: 'InvalidSession';
  message: Scalars['String'];
};

export type JsonPublicKeyInput = {
  algorithmVersion: AlgorithmVersion;
  publicKey: Scalars['String'];
};

export type JsonSignatureInput = {
  algorithmVersion: AlgorithmVersion;
  data: Scalars['String'];
};

export type LogoutError = InvalidSession;

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<LogoutError>>;
};

export type LogoutUnauthenticatedError = InvalidSession;

export type LogoutUnauthenticatedPayload = {
  __typename?: 'LogoutUnauthenticatedPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<LogoutUnauthenticatedError>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  checkChallengeCode: CheckChallengeCodePayload;
  logout: LogoutPayload;
  logoutUnauthenticated: LogoutUnauthenticatedPayload;
  openSessionFromBorne: OpenSessionFromBornePayload;
  refreshSession: RefreshSessionPayload;
  sendChallengeCode: SendChallengeCodePayload;
};


export type MutationCheckChallengeCodeArgs = {
  input: CheckChallengeCodeInput;
};


export type MutationOpenSessionFromBorneArgs = {
  input: OpenSessionFromBorneInput;
};


export type MutationRefreshSessionArgs = {
  input: RefreshSessionInput;
};


export type MutationSendChallengeCodeArgs = {
  input: SendChallengeCodeInput;
};

export type NoChallengeError = Error & {
  __typename?: 'NoChallengeError';
  message: Scalars['String'];
};

export type OpenSessionFromBorneError = InvalidCodeError | InvalidSession;

export type OpenSessionFromBorneInput = {
  publicKey: JsonPublicKeyInput;
  signInCode: Scalars['String'];
};

export type OpenSessionFromBornePayload = {
  __typename?: 'OpenSessionFromBornePayload';
  errors?: Maybe<Array<OpenSessionFromBorneError>>;
  unauthenticatedAssemblySessionInfo?: Maybe<UnauthenticatedAssemblySessionInfo>;
};

export type Query = {
  __typename?: 'Query';
  unauthenticatedStakeholderInfo: BasicStakeholderInfo;
};

export type RefreshSessionError = InvalidChallengeError | InvalidSession;

export type RefreshSessionInput = {
  sessionId: Scalars['UUID'];
  signature: JsonSignatureInput;
};

export type RefreshSessionPayload = {
  __typename?: 'RefreshSessionPayload';
  errors?: Maybe<Array<RefreshSessionError>>;
  sessionRefreshData?: Maybe<SessionRefreshData>;
};

export type SendChallengeCodeError = ChallengeExpiredError | FactorAlreadyValidatedError | FactorUnavailableError | InvalidSession | TooManyAttemptsError | TooSoonError;

export type SendChallengeCodeInput = {
  factorType: FactorType;
};

export type SendChallengeCodePayload = {
  __typename?: 'SendChallengeCodePayload';
  challengeSent?: Maybe<ChallengeSent>;
  errors?: Maybe<Array<SendChallengeCodeError>>;
};

export type SessionRefreshData = {
  __typename?: 'SessionRefreshData';
  isAuthenticated: Scalars['Boolean'];
  newChallengePayload: Scalars['String'];
  sessionToken: Scalars['String'];
};

export type TooManyAttemptsError = Error & {
  __typename?: 'TooManyAttemptsError';
  message: Scalars['String'];
};

export type TooSoonError = Error & {
  __typename?: 'TooSoonError';
  message: Scalars['String'];
};

export type UnauthenticatedAssemblySessionInfo = {
  __typename?: 'UnauthenticatedAssemblySessionInfo';
  firstChallenge: Scalars['String'];
  sessionId: Scalars['UUID'];
};

export type GetUnauthenticatedStakeholderInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnauthenticatedStakeholderInfoQuery = { __typename?: 'Query', unauthenticatedStakeholderInfo: { __typename?: 'BasicStakeholderInfo', email?: string | null, firstName: string, lastName: string, phoneNumber?: string | null } };

export type OpenTabletSessionMutationVariables = Exact<{
  code: Scalars['String'];
  publicKey: JsonPublicKeyInput;
}>;


export type OpenTabletSessionMutation = { __typename?: 'Mutation', openSessionFromBorne: { __typename?: 'OpenSessionFromBornePayload', errors?: Array<{ __typename: 'InvalidCodeError', remainingAttempts?: number | null } | { __typename: 'InvalidSession' }> | null, unauthenticatedAssemblySessionInfo?: { __typename?: 'UnauthenticatedAssemblySessionInfo', firstChallenge: string, sessionId: any } | null } };

export type RefreshSessionMutationVariables = Exact<{
  sessionId: Scalars['UUID'];
  signature: JsonSignatureInput;
}>;


export type RefreshSessionMutation = { __typename?: 'Mutation', refreshSession: { __typename?: 'RefreshSessionPayload', errors?: Array<{ __typename: 'InvalidChallengeError' } | { __typename: 'InvalidSession' }> | null, sessionRefreshData?: { __typename?: 'SessionRefreshData', sessionToken: string, isAuthenticated: boolean, newChallengePayload: string } | null } };

export type SendChallengeCodeMutationVariables = Exact<{
  factorType: FactorType;
}>;


export type SendChallengeCodeMutation = { __typename?: 'Mutation', sendChallengeCode: { __typename?: 'SendChallengeCodePayload', errors?: Array<{ __typename: 'ChallengeExpiredError' } | { __typename: 'FactorAlreadyValidatedError' } | { __typename: 'FactorUnavailableError' } | { __typename: 'InvalidSession' } | { __typename: 'TooManyAttemptsError' } | { __typename: 'TooSoonError' }> | null, challengeSent?: { __typename?: 'ChallengeSent', remainingAttempts: number } | null } };

export type CheckChallengeCodeMutationVariables = Exact<{
  factorType: FactorType;
  code: Scalars['String'];
}>;


export type CheckChallengeCodeMutation = { __typename?: 'Mutation', checkChallengeCode: { __typename?: 'CheckChallengeCodePayload', boolean?: boolean | null, errors?: Array<{ __typename: 'ChallengeExpiredError' } | { __typename: 'FactorAlreadyValidatedError' } | { __typename: 'InvalidCodeError' } | { __typename: 'InvalidSession' } | { __typename: 'NoChallengeError' } | { __typename: 'TooManyAttemptsError' }> | null } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: { __typename?: 'LogoutPayload', boolean?: boolean | null, errors?: Array<{ __typename: 'InvalidSession' }> | null } };

export type LogoutUnauthenticatedMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutUnauthenticatedMutation = { __typename?: 'Mutation', logoutUnauthenticated: { __typename?: 'LogoutUnauthenticatedPayload', boolean?: boolean | null, errors?: Array<{ __typename: 'InvalidSession' }> | null } };


export const GetUnauthenticatedStakeholderInfoDocument = gql`
    query getUnauthenticatedStakeholderInfo {
  unauthenticatedStakeholderInfo {
    email
    firstName
    lastName
    phoneNumber
  }
}
    `;
export const OpenTabletSessionDocument = gql`
    mutation openTabletSession($code: String!, $publicKey: JsonPublicKeyInput!) {
  openSessionFromBorne(input: {publicKey: $publicKey, signInCode: $code}) {
    errors {
      __typename
      ... on InvalidCodeError {
        remainingAttempts
      }
    }
    unauthenticatedAssemblySessionInfo {
      firstChallenge
      sessionId
    }
  }
}
    `;
export const RefreshSessionDocument = gql`
    mutation refreshSession($sessionId: UUID!, $signature: JsonSignatureInput!) {
  refreshSession(input: {sessionId: $sessionId, signature: $signature}) {
    errors {
      __typename
    }
    sessionRefreshData {
      sessionToken
      isAuthenticated
      newChallengePayload
    }
  }
}
    `;
export const SendChallengeCodeDocument = gql`
    mutation sendChallengeCode($factorType: FactorType!) {
  sendChallengeCode(input: {factorType: $factorType}) {
    errors {
      __typename
    }
    challengeSent {
      remainingAttempts
    }
  }
}
    `;
export const CheckChallengeCodeDocument = gql`
    mutation checkChallengeCode($factorType: FactorType!, $code: String!) {
  checkChallengeCode(input: {factorType: $factorType, code: $code}) {
    errors {
      __typename
    }
    boolean
  }
}
    `;
export const LogoutDocument = gql`
    mutation logout {
  logout {
    errors {
      __typename
    }
    boolean
  }
}
    `;
export const LogoutUnauthenticatedDocument = gql`
    mutation logoutUnauthenticated {
  logoutUnauthenticated {
    errors {
      __typename
    }
    boolean
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getUnauthenticatedStakeholderInfo(variables?: GetUnauthenticatedStakeholderInfoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetUnauthenticatedStakeholderInfoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUnauthenticatedStakeholderInfoQuery>(GetUnauthenticatedStakeholderInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUnauthenticatedStakeholderInfo', 'query');
    },
    openTabletSession(variables: OpenTabletSessionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OpenTabletSessionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<OpenTabletSessionMutation>(OpenTabletSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'openTabletSession', 'mutation');
    },
    refreshSession(variables: RefreshSessionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RefreshSessionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RefreshSessionMutation>(RefreshSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'refreshSession', 'mutation');
    },
    sendChallengeCode(variables: SendChallengeCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SendChallengeCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendChallengeCodeMutation>(SendChallengeCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'sendChallengeCode', 'mutation');
    },
    checkChallengeCode(variables: CheckChallengeCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CheckChallengeCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CheckChallengeCodeMutation>(CheckChallengeCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'checkChallengeCode', 'mutation');
    },
    logout(variables?: LogoutMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LogoutMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LogoutMutation>(LogoutDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'logout', 'mutation');
    },
    logoutUnauthenticated(variables?: LogoutUnauthenticatedMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LogoutUnauthenticatedMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LogoutUnauthenticatedMutation>(LogoutUnauthenticatedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'logoutUnauthenticated', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;