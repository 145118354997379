// FOR DEBUG ONLY FOR NOW

import { AlgorithmVersion } from '~/lib/straightauth/cryptography/types'
import { destroyIndexedDb, openIndexedDb } from '~/lib/indexed-db'
import { getAlgorithm } from '~/lib/straightauth/cryptography'
import type { ActiveSession } from '~/lib/straightauth/logged-in'
import * as logger from '~/packages/core-utils/logging'

const DB_NAME = 'sa_keys'
const DB_VERSION = 1
const OBJECT_STORE_NAME = 'straightauth'

const TABLET_SESSION_KEY = 'TABLET_SESSION'

interface PersistedSession {
  id: string
  privateKey: unknown
  nextChallengePayload: string
}

export async function loadNextChallengePayload(): Promise<string | null> {
  const store = await openIndexedDb(DB_NAME, DB_VERSION, OBJECT_STORE_NAME)
  try {
    const rawObj = await store.get(TABLET_SESSION_KEY)

    if (!rawObj || typeof rawObj !== 'object') return null
    if (!('nextChallengePayload' in rawObj)) return null
    if (typeof rawObj.nextChallengePayload !== 'string') return null

    return rawObj.nextChallengePayload
  } finally {
    store.close()
  }
}

export async function recoverSession(): Promise<ActiveSession | null> {
  const store = await openIndexedDb(DB_NAME, DB_VERSION, OBJECT_STORE_NAME)
  try {
    const rawObj = await store.get(TABLET_SESSION_KEY)

    if (!rawObj || typeof rawObj !== 'object') return null
    if (!('id' in rawObj && 'privateKey' in rawObj && 'nextChallengePayload' in rawObj)) return null
    if (typeof rawObj.id !== 'string' || typeof rawObj.nextChallengePayload !== 'string')
      return null

    const privateKey = getAlgorithm(AlgorithmVersion.V1).importPrivateKeyFromSerializable(
      rawObj.privateKey
    )

    return { privateKey, sessionId: rawObj.id, nextChallengePayload: rawObj.nextChallengePayload }
  } finally {
    store.close()
  }
}

export async function persistSession(session: ActiveSession): Promise<void> {
  const store = await openIndexedDb(DB_NAME, DB_VERSION, OBJECT_STORE_NAME)
  try {
    // overwrite preexisting keys
    await store.remove(TABLET_SESSION_KEY)
    await store.add<PersistedSession>(TABLET_SESSION_KEY, {
      id: session.sessionId,
      privateKey: session.privateKey.toSerializable(),
      nextChallengePayload: session.nextChallengePayload,
    })
  } finally {
    store.close()
  }
}

export async function clear(): Promise<void> {
  try {
    await destroyIndexedDb(DB_NAME)
    logger.logInfo('StraightAuth', 'Session data cleared')
  } catch (e) {
    logger.logWarningWithException('StraightAuth/storage', e, 'Failed clear')
  }
}
