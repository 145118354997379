import { DateTime } from 'luxon'
import { logError, logWarning, logWarningWithException } from './logging'

const CRISP_APP_ID = process.env.CRISP_APP_ID
const CRISP_SRC = 'https://client.crisp.chat/l.js'

type CrispValue =
  | [string, string | boolean] // actions
  | ['do', string, [string]]
  | ['set', 'session:data', [[string, string][]]]
  | ['config', string, [null | boolean | number]]
  | ['set', 'session:event', [[[string, unknown, string]]]]
  | ['set', 'session:segments', [string[]]]
  | ['set', `user:${string}`, string[]] // set values
  | ['on', string, (...result: any) => void] // callbacks

interface Crisp extends Array<CrispValue> {
  get?: (k: string) => string | number
  set?: ([k, v, cb]: CrispValue) => void
  is?: (k: string) => boolean
}

declare global {
  // Window is a special TS interface, ignore the IDE warning
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    $crisp: Crisp
    CRISP_WEBSITE_ID: string
    CRISP_TOKEN_ID: string | undefined
    CRISP_READY_TRIGGER: (value: Event | PromiseLike<Event>) => void
    CRISP_RUNTIME_CONFIG: {

      session_merge: boolean
    }
  }
}

window.$crisp = []
let $crisp = window.$crisp
window.CRISP_RUNTIME_CONFIG = {
  session_merge: true
}
window.CRISP_WEBSITE_ID = CRISP_APP_ID!

export function getCrisp (): Crisp {
  return $crisp
}

let crispLauncher: HTMLElement | null = null
let containerHidden = false
let loaded = false
let keepAliveTimeout: NodeJS.Timeout | null = null
let forceKeepAlive = false
// 20 minutes
const KEEP_ALIVE_DELAY = 1000 * 60 * 20

const sendCrispToHell = () => $crisp.push(['config', 'container:index', [-666]])
const summonCrispBack = () => $crisp.push(['config', 'container:index', [666666]])

export function init (): void {
  if (!CRISP_APP_ID) {
    logWarning('Crisp', 'no app id found')
    return
  }

  if (loaded) {
    return
  }

  function handleCrispMessageEvent () {
    show()
    resetCrispKeepAliveTimeout()
  }

  loadScript()
    .then(() => {
      loaded = true
      $crisp = window.$crisp
      crispLauncher = document.querySelector('#crisp-chatbox > div > a')
      $crisp.push(['on', 'message:received', handleCrispMessageEvent])
      $crisp.push(['on', 'message:sent', handleCrispMessageEvent])
      $crisp.push(['safe', true]) // Disables warning about shimmed method in production
    })
    .catch(e => logWarningWithException('crisp', e, 'Failed to load Crisp script'))
}

export function toggle (): void {
  if (!loaded) {
    return
  }

  const action = $crisp.is!('chat:opened') ? 'close' : 'open'
  if (containerHidden) {
    if (action === 'close') {
      sendCrispToHell()
    } else {
      summonCrispBack()
    }
  }
  $crisp.push(['do', `chat:${action}`])
}

export function show (): void {
  if (!loaded) {
    return
  }

  if (containerHidden) {
    summonCrispBack()
  }
  $crisp.push(['do', 'chat:open'])
}

let timeout: NodeJS.Timeout | null = null

export function hideDefaultCrispLauncher (hide: boolean): void {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }

  if (!loaded) {
    timeout = setTimeout(() => hideDefaultCrispLauncher(hide), 500)
    return
  }

  if (hide) {
    hideCrispLauncher()
    $crisp.push(['do', 'chat:close'])
  } else {
    showCrispLauncher()
  }
}

function hideCrispLauncher (): void {
  if (!loaded) {
    return
  }

  containerHidden = true
  if ($crisp.is!('chat:closed')) {
    sendCrispToHell()
  }
  $crisp.push(['on', 'chat:closed', sendCrispToHell])
  if (crispLauncher) {
    crispLauncher.style.display = 'none!important'
  }
}

function showCrispLauncher (): void {
  if (!loaded) {
    return
  }
  containerHidden = false
  summonCrispBack()
  $crisp.push([
    'on',
    'chat:closed',
    () => {
      /* ... */
    }
  ])
}

function buildFaRoot () {
  let environment = ''
  switch (process.env.ENVIRONMENT) {
    case 'development': {
      environment = 'Development%20%7C%20paulstenne'
      break
    }
    case 'staging': {
      environment = 'Staging'
      break
    }
    case 'production': {
      environment = 'Production'
      break
    }
  }

  return `https://app.forestadmin.com/N%C3%BCag/${environment}/Op%C3%A9rateurs`
}

/**
 * Update the email without update the user, for example during login
 * This email will not be verified
 * @param email Email to add to the profile
 */
export function updateEmailWithoutUser (email: string) {
  try {
    $crisp.push(['set', 'user:email', [email]])
  } catch (e) {
    logError('crisp', e, 'when trying to set email without user')
  }
}

function resetCrisp () {
  if (!loaded) {
    return
  }
  const action = $crisp.is!('chat:opened') ? 'open' : 'close'

  $crisp.push(['do', 'session:reset'])
  $crisp.push(['config', 'position:reverse', [window.CRISP_TOKEN_ID !== undefined]])

  if (containerHidden) {
    if (action === 'close') {
      sendCrispToHell()
    } else {
      summonCrispBack()
    }
  }
  $crisp.push(['do', `chat:${action}`])
}

function keepAliveCrisp () {
  resetCrisp()
  keepAliveTimeout = setTimeout(keepAliveCrisp, KEEP_ALIVE_DELAY)
}

export function startCrispKeepAlive () {
  if (keepAliveTimeout === null) {
    keepAliveTimeout = setTimeout(keepAliveCrisp, KEEP_ALIVE_DELAY)
  }
}

function resetCrispKeepAliveTimeout () {
  if (keepAliveTimeout === null) {
    return
  }
  clearTimeout(keepAliveTimeout)
  keepAliveTimeout = setTimeout(keepAliveCrisp, KEEP_ALIVE_DELAY)
}

export function stopCrispKeepAlive () {
  try {
    if (keepAliveTimeout === null) {
      logError('crisp', null, 'tried to stop interval but was not started')
      return
    }

    if (!forceKeepAlive) {
      clearTimeout(keepAliveTimeout)
      keepAliveTimeout = null
    }
  } catch (e) {
    logError('crisp', e, 'failed stopping keepalive')
  }
}

export interface CrispOrganization {
  id: string
  name: string
}

export interface CrispUser {
  id: string
  firstName: string
  lastName: string
  email: string

  crispHash: string
  secretChatToken: string

  organizations: CrispOrganization[]
}

export function updateUser (user?: Readonly<CrispUser> | null): void {
  // Crisp crashing is interpreted as a browser incompatibility
  // There exists some user input data in the keys we use on Crisp, for example to set organization links
  // As such we'll wrap up the whole thing in a try/catch and silently crash if there's any issue
  // The consequence of which is that we won't have much data on the support side, which is not as bad as a hard app crash
  try {
    if (!loaded) {
      setTimeout(() => updateUser(user), 500)
      return
    }

    const mustReset =
      (!window.CRISP_TOKEN_ID && !!user) || // No existing token and a user, we're logging in
      (!!window.CRISP_TOKEN_ID && !user) // A token but no user, we're logging out

    window.CRISP_TOKEN_ID = user?.secretChatToken

    if (mustReset) {
      resetCrisp()
    }

    if (user) {
      const profileUrl = `[Utilisateur FA](${buildFaRoot()}/data/User/index/record/User/${user.id.toUpperCase()}/summary)`
      $crisp.push(['config', 'position:reverse', [true]])
      $crisp.push(['set', 'user:email', [user.email, user.crispHash]])
      $crisp.push(['set', 'user:nickname', [`${user.firstName} ${user.lastName}`]])
      $crisp.push(['set', 'session:data', [[['Profil', profileUrl]]]])
      if (user.organizations.length > 0) {
        startCrispKeepAlive()
        forceKeepAlive = true
        addUserSegments('organizer')
        user.organizations.forEach(({ name }) => addUserSegments(name))
        $crisp.push([
          'set',
          'user:company',
          [user.organizations.map(({ name }) => name).join(', ')]
        ])
        $crisp.push([
          'set',
          'session:data',
          [
            user.organizations.map(
              ({ name, id }, index) =>
                [
                  'org_' + index,
                  `[Orga ${name}](${buildFaRoot()}/data/Organization/index/record/Organization/${id.toUpperCase()}/summary)`
                ] as [string, string]
            )
          ]
        ])
      }
    }
  } catch (e) {
    logError('crisp', e, 'failed updating user')
  }
}

export type StakeholderData = {
  organizerName: string
  stakeholderId: string

  assemblyId: string

  assemblyDate: string
}

export function updateUserStakeholderData ({
  organizerName,
  stakeholderId,
  assemblyId,
  assemblyDate
}: StakeholderData) {
  try {
    addUserSegments('participant')
    addUserSegments(organizerName)

    const assemblyIdFirstPart = assemblyId.split('-')[0]
    const key = `part_${assemblyIdFirstPart}`
    const date = DateTime.fromISO(assemblyDate).toLocaleString(DateTime.DATE_SHORT, {
      locale: 'fr'
    })
    const data = `[Participant de ${organizerName} (${date})](${buildFaRoot()}/data/Stakeholder/index/record/Stakeholder/${stakeholderId.toUpperCase()}/summary)`
    $crisp.push(['set', 'session:data', [[[key, data]]]])
  } catch (e) {
    logError('crisp', e, 'failed updating stakeholder')
  }
}

export function addUserSegments (...segmentsToAdd: string[]) {
  try {
    $crisp.push(['set', 'session:segments', [segmentsToAdd]])
  } catch (e) {
    logError('crisp', e, 'failed updating segments')
  }
}

export enum CrispEvent {
  Info,
  Warning,
  Error,
}

const eventToColour: Record<CrispEvent, string> = {
  [CrispEvent.Info]: 'blue',
  [CrispEvent.Warning]: 'yellow',
  [CrispEvent.Error]: 'red'
}

export function registerEvent (eventType: CrispEvent, message: string, data?: unknown) {
  try {
    const colour = eventToColour[eventType]
    $crisp.push(['set', 'session:event', [[[message, { data: JSON.stringify(data) }, colour]]]])
  } catch (e) {
    // Don't use logError to avoid potential error loop
    // eslint-disable-next-line no-console
    console.error('failed registering Crisp event', e)
  }
}

function loadScript (): Promise<Event> {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.src = CRISP_SRC
    script.onerror = reject
    window.CRISP_READY_TRIGGER = resolve
    const scriptParent = document.getElementsByTagName('script')[0]?.parentNode ?? document.head
    scriptParent.appendChild(script)
  })
}
