import { render, staticRenderFns } from "./sidebar.vue?vue&type=template&id=49659d40"
import script from "./sidebar.vue?vue&type=script&lang=ts"
export * from "./sidebar.vue?vue&type=script&lang=ts"
import style0 from "./sidebar.vue?vue&type=style&index=0&id=49659d40&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonSidebar: require('/mnt/vss/_work/1/s/components/common/sidebar.vue').default,CommonTopBar: require('/mnt/vss/_work/1/s/components/common/top-bar.vue').default,CommonAppContainer: require('/mnt/vss/_work/1/s/components/common/app-container.vue').default})
