import { computed, ref } from 'vue'
import { useRoute } from '#app'
import { loggerError, logWarning } from 'nuag-core-utils/logging'
import { getCrisp } from 'nuag-core-utils/crisp'
import { sessionInfo } from '~/lib/zerauth'

export type KbItem = {
  title: string
  link: string
}

type KbItemsByRoute = Record<string, KbItem[]>

const KB_ITEMS_URL = `${process.env.PUBLIC_CDN}/static/kb.json`

const defaultItemsOrganizer: KbItem[] = [
  {
    title: 'Quelles sont les options de vote et de pouvoir ?',
    link: 'https://help.nuag.fr/fr/article/options-de-vote-et-de-pouvoirs-evuxia/',
  },
  {
    title: 'Comment préparer mon fichier de participants ?',
    link: 'https://help.nuag.fr/fr/article/comment-preparer-mon-fichier-de-participants-1yji3c1/',
  },
  {
    title: 'Ai-je besoin de plusieurs comptes sur Nüag ?',
    link: 'https://help.nuag.fr/fr/article/ai-je-besoin-de-plusieurs-comptes-sur-nuag-1kosfce/',
  },
]

const defaultItemsParticipant: KbItem[] = [
  {
    title: 'Comment me déconnecter ?',
    link: 'https://help.nuag.fr/fr/article/comment-me-deconnecter-1e91ta6/',
  },
  {
    title: 'Comment voter avec une délégation de pouvoir ?',
    link: 'https://help.nuag.fr/fr/article/comment-voter-avec-une-delegation-de-pouvoir-jrvolm/',
  },
]

const itemsByRoute = ref<KbItemsByRoute>({})

function checkItemsValid(items: object): items is KbItemsByRoute {
  return Object.entries(items).every(function ([key, kbArray]: [string, unknown]) {
    if (typeof kbArray !== 'object' || kbArray === null || !Array.isArray(kbArray)) {
      logWarning('kb-fetcher', 'value is not array', key)
      return false
    }
    for (const kbItem of kbArray) {
      if (typeof kbItem !== 'object' || kbItem === null) {
        logWarning('kb-fetcher', 'entry is either not an object or null', key)
        return false
      }
      const kbItemCast = kbItem as { title: unknown; link: unknown }
      if (!(typeof kbItemCast.title === 'string' && typeof kbItemCast.link === 'string')) {
        logWarning('kb-fetcher', 'entry is lacking either title or link or mistyped', key)
        return false
      }
    }
    return true
  })
}

// Fetch items on app start
;(async function fetchItems() {
  const res = await fetch(KB_ITEMS_URL)
  if (!res.ok) {
    return
  }
  const items = await res.json()
  if (!checkItemsValid(items)) {
    throw new Error('kb failed type check')
  }
  itemsByRoute.value = items
})().catch(loggerError('kb-fetcher', 'unable to fetch kb items'))

export function useKbItems() {
  const route = useRoute()
  const isOrganizer = (sessionInfo.me?.organizations?.length || 0) > 0
  return computed(() => {
    const routeName = route.name
    const defaultItems = isOrganizer ? defaultItemsOrganizer : defaultItemsParticipant
    if (routeName) {
      return itemsByRoute.value[routeName] || defaultItems
    }
    return defaultItems
  })
}

let lastSearch: string | null = null
export function searchKb(query: string): Promise<KbItem[]> {
  const crisp = getCrisp()
  lastSearch = query
  return new Promise((resolve) => {
    crisp.push(['do', 'helpdesk:query', [query]])
    crisp.push([
      'on',
      'helpdesk:queried',
      ({ query, results }) => {
        if (query === lastSearch) {
          resolve(
            (results as Array<{ title: string; url: string }>).map(({ title, url }) => ({
              title,
              link: url,
            }))
          )
        }
      },
    ])
  })
}
