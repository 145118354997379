import { render, staticRenderFns } from "./sidebar.vue?vue&type=template&id=5660ed6c&xmlns%3Aaria=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml"
import script from "./sidebar.vue?vue&type=script&lang=ts"
export * from "./sidebar.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./sidebar.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FaIcon: require('/mnt/vss/_work/1/s/components/fa-icon.vue').default,CommonHelpPanel: require('/mnt/vss/_work/1/s/components/common/help-panel.vue').default})
