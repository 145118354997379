import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Long: any;
  UUID: any;
};

export type BasicOrganization = {
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type BasicUser = {
  country: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
};

export enum ContractVersion {
  GdprAndCseUpdate = 'GDPR_AND_CSE_UPDATE',
  /** @deprecated Old contract version */
  InitialVersion = 'INITIAL_VERSION'
}

export type EntityInformationInput = {
  entityAddress: Scalars['String'];
  entityEmail: Scalars['String'];
  entityLegalNumber: Scalars['String'];
  entityName: Scalars['String'];
  entityType: OrganizationEntityType;
};

export type MasterKeyInput = {
  algorithmVersion: Scalars['Int'];
  encryptedKey: Scalars['String'];
  iv: Scalars['String'];
  salt: Scalars['String'];
};

export type MasterKeyUpdate = {
  encryptedKey: Scalars['String'];
  iv: Scalars['String'];
  salt: Scalars['String'];
};

export type Membership = {
  id: Scalars['UUID'];
  organization: BasicOrganization;
  /** @deprecated This is useless and always has been */
  role: Scalars['Int'];
  user: BasicUser;
};

export type Mutation = {
  acceptPendingMembership: Membership;
  /** @deprecated Delete this, it should no longer be used by the front */
  addMember: PendingMembership;
  /** @deprecated Was never used */
  addSecondaryEmail: Scalars['Boolean'];
  addSecondaryEmailWithSecret: Scalars['Boolean'];
  addSecondaryKey: Scalars['Boolean'];
  createOrganization: OrganizationType;
  createOrganizationWithAllData: Scalars['UUID'];
  deleteAllSessions: Scalars['Boolean'];
  deleteSession: Scalars['Boolean'];
  inviteMember: PendingMembership;
  populateEntityInformation: OrganizationType;
  registerOrganizationSignature: OrganizationType;
  rejectPendingMembership: Scalars['Boolean'];
  removeMembership: OrganizationType;
  requestAddSecondaryEmail: Scalars['Boolean'];
  updateMasterKey: Scalars['Boolean'];
  updateOrganizationName: OrganizationType;
  updateSelf: User;
};


export type MutationAcceptPendingMembershipArgs = {
  id: Scalars['UUID'];
};


export type MutationAddMemberArgs = {
  email: Scalars['String'];
  id: Scalars['UUID'];
  role: Scalars['Int'];
};


export type MutationAddSecondaryEmailArgs = {
  code: Scalars['String'];
  email: Scalars['String'];
};


export type MutationAddSecondaryEmailWithSecretArgs = {
  secret: Scalars['String'];
};


export type MutationAddSecondaryKeyArgs = {
  newSecondaryKey: SecondaryKeyInput;
};


export type MutationCreateOrganizationArgs = {
  id: Scalars['UUID'];
  name: Scalars['String'];
};


export type MutationCreateOrganizationWithAllDataArgs = {
  entityInformation: EntityInformationInput;
  name: Scalars['String'];
  signPayload: Scalars['String'];
};


export type MutationDeleteSessionArgs = {
  id: Scalars['UUID'];
};


export type MutationInviteMemberArgs = {
  email: Scalars['String'];
  id: Scalars['UUID'];
};


export type MutationPopulateEntityInformationArgs = {
  entityInformation: EntityInformationInput;
  organizationId: Scalars['UUID'];
};


export type MutationRegisterOrganizationSignatureArgs = {
  organizationId: Scalars['UUID'];
  signPayload: Scalars['String'];
};


export type MutationRejectPendingMembershipArgs = {
  id: Scalars['UUID'];
};


export type MutationRemoveMembershipArgs = {
  organizationId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


export type MutationRequestAddSecondaryEmailArgs = {
  email: Scalars['String'];
};


export type MutationUpdateMasterKeyArgs = {
  newMasterKey: MasterKeyInput;
};


export type MutationUpdateOrganizationNameArgs = {
  id: Scalars['UUID'];
  name: Scalars['String'];
};


export type MutationUpdateSelfArgs = {
  commercialSolicitations: Scalars['Boolean'];
  emailNotifications: Scalars['Boolean'];
  preferredLocale: Scalars['String'];
};

export enum OrganizationEntityType {
  Association = 'ASSOCIATION',
  Cooperative = 'COOPERATIVE',
  Corporate = 'CORPORATE',
  Unknown = 'UNKNOWN'
}

export type OrganizationType = {
  avatarUpdateToken: Scalars['String'];
  canSign: Scalars['Boolean'];
  entityAddress?: Maybe<Scalars['String']>;
  entityEmail?: Maybe<Scalars['String']>;
  entityLegalNumber?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  entityType: OrganizationEntityType;
  id: Scalars['UUID'];
  isHsbcOrganization: Scalars['Boolean'];
  isLastVersionSigned: Scalars['Boolean'];
  membership: Membership;
  memberships: Array<Membership>;
  name: Scalars['String'];
  nuagContractSignedAt?: Maybe<Scalars['DateTime']>;
  nuagContractSignedBy?: Maybe<Scalars['UUID']>;
  nuagContractSignedVersion?: Maybe<ContractVersion>;
  pendingMemberships: Array<PendingMembership>;
};


export type OrganizationTypeMembershipArgs = {
  memberId: Scalars['UUID'];
};

export type PendingMembership = {
  email: Scalars['String'];
  id: Scalars['UUID'];
  organization: BasicOrganization;
  /** @deprecated This is useless and always has been */
  role: Scalars['Int'];
  userId: Scalars['UUID'];
};

export type Query = {
  me: User;
  orgaForAm: OrganizationType;
  timeDrift: Scalars['Long'];
};


export type QueryOrgaForAmArgs = {
  orgId: Scalars['UUID'];
};


export type QueryTimeDriftArgs = {
  localTimestamp: Scalars['Long'];
};

export type SecondaryEmail = {
  email: Scalars['String'];
  isPending: Scalars['Boolean'];
};

export type SecondaryKey = {
  encryptedKey: Scalars['String'];
  id: Scalars['UUID'];
  iv: Scalars['String'];
  name: Scalars['String'];
  publicKey: Scalars['String'];
  usage: SecondaryKeyUsage;
};

export type SecondaryKeyInput = {
  algorithmVersion: Scalars['Int'];
  encryptedKey: Scalars['String'];
  id: Scalars['UUID'];
  iv: Scalars['String'];
  name: Scalars['String'];
  publicKey: Scalars['String'];
  usage: SecondaryKeyUsage;
};

export enum SecondaryKeyUsage {
  Derivation = 'DERIVATION',
  Encryption = 'ENCRYPTION',
  Hashing = 'HASHING',
  Nurne = 'NURNE',
  Signature = 'SIGNATURE',
  Turones = 'TURONES',
  Verification = 'VERIFICATION'
}

export type SessionType = {
  expiresAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  remoteIp?: Maybe<Scalars['String']>;
  userAgent: Scalars['String'];
};

export type User = {
  avatarUpdateToken: Scalars['String'];
  commercialSolicitations: Scalars['Boolean'];
  country: Scalars['String'];
  crispHash: Scalars['String'];
  email: Scalars['String'];
  emailNotifications: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  masterKeyUpdateData: MasterKeyUpdate;
  organization: OrganizationType;
  organizations: Array<OrganizationType>;
  pendingMembership: PendingMembership;
  pendingMemberships: Array<PendingMembership>;
  preferredLocale: Scalars['String'];
  secondaryEmails: Array<SecondaryEmail>;
  secondaryKey?: Maybe<SecondaryKey>;
  secondaryKeys: Array<SecondaryKey>;
  secretChatToken: Scalars['UUID'];
  session: SessionType;
  sessions: Array<SessionType>;
};


export type UserOrganizationArgs = {
  organizationId: Scalars['UUID'];
};


export type UserPendingMembershipArgs = {
  membershipId: Scalars['UUID'];
};


export type UserSecondaryKeyArgs = {
  name: Scalars['String'];
};


export type UserSessionArgs = {
  sessionId: Scalars['UUID'];
};

export type FlatOrganizationDataFragment = { id: any, name: string, entityName?: string | null, entityAddress?: string | null, entityEmail?: string | null, entityLegalNumber?: string | null, entityType: OrganizationEntityType, canSign: boolean, nuagContractSignedVersion?: ContractVersion | null, isLastVersionSigned: boolean, isHsbcOrganization: boolean };

export type AllOrganizationDataFragment = { id: any, name: string, entityName?: string | null, entityAddress?: string | null, entityEmail?: string | null, entityLegalNumber?: string | null, entityType: OrganizationEntityType, canSign: boolean, nuagContractSignedVersion?: ContractVersion | null, isLastVersionSigned: boolean, isHsbcOrganization: boolean, memberships: Array<{ id: any, user: { id: any, firstName: string, lastName: string, fullName: string } }>, pendingMemberships: Array<{ userId: any, email: string }> };

export type FetchMeQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchMeQuery = { me: { commercialSolicitations: boolean, country: string, crispHash: string, email: string, emailNotifications: boolean, firstName: string, id: any, lastName: string, preferredLocale: string, secretChatToken: any, secondaryEmails: Array<{ email: string, isPending: boolean }>, organizations: Array<{ id: any, name: string, entityName?: string | null, entityAddress?: string | null, entityEmail?: string | null, entityLegalNumber?: string | null, entityType: OrganizationEntityType, canSign: boolean, nuagContractSignedVersion?: ContractVersion | null, isLastVersionSigned: boolean, isHsbcOrganization: boolean }>, secondaryKeys: Array<{ encryptedKey: string, id: any, iv: string, name: string, publicKey: string, usage: SecondaryKeyUsage }>, pendingMemberships: Array<{ id: any, userId: any, organization: { id: any, name: string } }> } };

export type FetchMeSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchMeSettingsQuery = { me: { commercialSolicitations: boolean, emailNotifications: boolean, preferredLocale: string } };

export type FetchOrganizationQueryVariables = Exact<{
  organizationId: Scalars['UUID'];
}>;


export type FetchOrganizationQuery = { me: { organization: { id: any, name: string, entityName?: string | null, entityAddress?: string | null, entityEmail?: string | null, entityLegalNumber?: string | null, entityType: OrganizationEntityType, canSign: boolean, nuagContractSignedVersion?: ContractVersion | null, isLastVersionSigned: boolean, isHsbcOrganization: boolean, memberships: Array<{ id: any, user: { id: any, firstName: string, lastName: string, fullName: string } }>, pendingMemberships: Array<{ userId: any, email: string }> } } };

export type FetchAllOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchAllOrganizationsQuery = { me: { organizations: Array<{ id: any, name: string, entityName?: string | null, entityAddress?: string | null, entityEmail?: string | null, entityLegalNumber?: string | null, entityType: OrganizationEntityType, canSign: boolean, nuagContractSignedVersion?: ContractVersion | null, isLastVersionSigned: boolean, isHsbcOrganization: boolean, memberships: Array<{ id: any, user: { id: any, firstName: string, lastName: string, fullName: string } }>, pendingMemberships: Array<{ userId: any, email: string }> }> } };

export type FetchOrganizationSignatureDataQueryVariables = Exact<{
  organizationId: Scalars['UUID'];
}>;


export type FetchOrganizationSignatureDataQuery = { me: { organization: { canSign: boolean, nuagContractSignedVersion?: ContractVersion | null, isLastVersionSigned: boolean } } };

export type DeleteSessionMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteSessionMutation = { deleteSession: boolean };

export type FetchMasterKeyQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchMasterKeyQuery = { me: { masterKeyUpdateData: { encryptedKey: string, iv: string, salt: string } } };

export type FetchSecondaryKeyQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type FetchSecondaryKeyQuery = { me: { secondaryKey?: { encryptedKey: string, id: any, iv: string, name: string, publicKey: string, usage: SecondaryKeyUsage } | null } };

export type UpdateMasterKeyMutationVariables = Exact<{
  newMasterKey: MasterKeyInput;
}>;


export type UpdateMasterKeyMutation = { updateMasterKey: boolean };

export type AddSecondaryKeyMutationVariables = Exact<{
  newSecondaryKey: SecondaryKeyInput;
}>;


export type AddSecondaryKeyMutation = { addSecondaryKey: boolean };

export type FetchOrganizationAvatarUpdateTokenQueryVariables = Exact<{
  organizationId: Scalars['UUID'];
}>;


export type FetchOrganizationAvatarUpdateTokenQuery = { me: { organization: { avatarUpdateToken: string } } };

export type FetchUserAvatarUpdateTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchUserAvatarUpdateTokenQuery = { me: { avatarUpdateToken: string } };

export type UpdateSelfMutationVariables = Exact<{
  commercialSolicitations: Scalars['Boolean'];
  emailNotifications: Scalars['Boolean'];
  preferredLocale: Scalars['String'];
}>;


export type UpdateSelfMutation = { updateSelf: { id: any, commercialSolicitations: boolean, emailNotifications: boolean, preferredLocale: string } };

export type CreateOrganizationMutationVariables = Exact<{
  name: Scalars['String'];
  id: Scalars['UUID'];
}>;


export type CreateOrganizationMutation = { createOrganization: { id: any, name: string } };

export type PopulateEntityInformationMutationVariables = Exact<{
  organizationId: Scalars['UUID'];
  entityInformation: EntityInformationInput;
}>;


export type PopulateEntityInformationMutation = { populateEntityInformation: { id: any, name: string, entityName?: string | null, entityAddress?: string | null, entityEmail?: string | null, entityLegalNumber?: string | null, entityType: OrganizationEntityType, canSign: boolean, nuagContractSignedVersion?: ContractVersion | null, isLastVersionSigned: boolean, isHsbcOrganization: boolean, memberships: Array<{ id: any, user: { id: any, firstName: string, lastName: string, fullName: string } }>, pendingMemberships: Array<{ userId: any, email: string }> } };

export type RemoveMembershipMutationVariables = Exact<{
  userId: Scalars['UUID'];
  organizationId: Scalars['UUID'];
}>;


export type RemoveMembershipMutation = { removeMembership: { id: any } };

export type AcceptPendingMembershipMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AcceptPendingMembershipMutation = { acceptPendingMembership: { id: any } };

export type RejectPendingMembershipMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RejectPendingMembershipMutation = { rejectPendingMembership: boolean };

export type SignDocumentMutationVariables = Exact<{
  organizationId: Scalars['UUID'];
  signPayload: Scalars['String'];
}>;


export type SignDocumentMutation = { registerOrganizationSignature: { id: any } };

export type CreateOrganizationWithAllDataMutationVariables = Exact<{
  name: Scalars['String'];
  entityInformation: EntityInformationInput;
  signPayload: Scalars['String'];
}>;


export type CreateOrganizationWithAllDataMutation = { createOrganizationWithAllData: any };

export type InviteMemberMutationVariables = Exact<{
  email: Scalars['String'];
  id: Scalars['UUID'];
}>;


export type InviteMemberMutation = { inviteMember: { id: any, userId: any } };

export type AmOrganizationQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AmOrganizationQuery = { orgaForAm: { id: any, name: string, entityName?: string | null, entityAddress?: string | null, entityEmail?: string | null, entityLegalNumber?: string | null, entityType: OrganizationEntityType, canSign: boolean, nuagContractSignedVersion?: ContractVersion | null, isLastVersionSigned: boolean, isHsbcOrganization: boolean, memberships: Array<{ id: any, user: { id: any, firstName: string, lastName: string, fullName: string } }>, pendingMemberships: Array<{ userId: any, email: string }> } };

export const FlatOrganizationDataFragmentDoc = gql`
    fragment FlatOrganizationData on OrganizationType {
  id
  name
  entityName
  entityAddress
  entityEmail
  entityLegalNumber
  entityType
  canSign
  nuagContractSignedVersion
  isLastVersionSigned
  isHsbcOrganization
}
    `;
export const AllOrganizationDataFragmentDoc = gql`
    fragment AllOrganizationData on OrganizationType {
  ...FlatOrganizationData
  memberships {
    id
    user {
      id
      firstName
      lastName
      fullName
    }
  }
  pendingMemberships {
    userId
    email
  }
}
    ${FlatOrganizationDataFragmentDoc}`;
export const FetchMeDocument = gql`
    query fetchMe {
  me {
    commercialSolicitations
    country
    crispHash
    email
    emailNotifications
    firstName
    id
    lastName
    preferredLocale
    secretChatToken
    secondaryEmails {
      email
      isPending
    }
    organizations {
      ...FlatOrganizationData
    }
    secondaryKeys {
      encryptedKey
      id
      iv
      name
      publicKey
      usage
    }
    pendingMemberships {
      id
      userId
      organization {
        id
        name
      }
    }
  }
}
    ${FlatOrganizationDataFragmentDoc}`;
export const FetchMeSettingsDocument = gql`
    query fetchMeSettings {
  me {
    commercialSolicitations
    emailNotifications
    preferredLocale
  }
}
    `;
export const FetchOrganizationDocument = gql`
    query fetchOrganization($organizationId: UUID!) {
  me {
    organization(organizationId: $organizationId) {
      ...AllOrganizationData
    }
  }
}
    ${AllOrganizationDataFragmentDoc}`;
export const FetchAllOrganizationsDocument = gql`
    query fetchAllOrganizations {
  me {
    organizations {
      ...AllOrganizationData
    }
  }
}
    ${AllOrganizationDataFragmentDoc}`;
export const FetchOrganizationSignatureDataDocument = gql`
    query fetchOrganizationSignatureData($organizationId: UUID!) {
  me {
    organization(organizationId: $organizationId) {
      canSign
      nuagContractSignedVersion
      isLastVersionSigned
    }
  }
}
    `;
export const DeleteSessionDocument = gql`
    mutation deleteSession($id: UUID!) {
  deleteSession(id: $id)
}
    `;
export const FetchMasterKeyDocument = gql`
    query fetchMasterKey {
  me {
    masterKeyUpdateData {
      encryptedKey
      iv
      salt
    }
  }
}
    `;
export const FetchSecondaryKeyDocument = gql`
    query fetchSecondaryKey($name: String!) {
  me {
    secondaryKey(name: $name) {
      encryptedKey
      id
      iv
      name
      publicKey
      usage
    }
  }
}
    `;
export const UpdateMasterKeyDocument = gql`
    mutation updateMasterKey($newMasterKey: MasterKeyInput!) {
  updateMasterKey(newMasterKey: $newMasterKey)
}
    `;
export const AddSecondaryKeyDocument = gql`
    mutation addSecondaryKey($newSecondaryKey: SecondaryKeyInput!) {
  addSecondaryKey(newSecondaryKey: $newSecondaryKey)
}
    `;
export const FetchOrganizationAvatarUpdateTokenDocument = gql`
    query fetchOrganizationAvatarUpdateToken($organizationId: UUID!) {
  me {
    organization(organizationId: $organizationId) {
      avatarUpdateToken
    }
  }
}
    `;
export const FetchUserAvatarUpdateTokenDocument = gql`
    query fetchUserAvatarUpdateToken {
  me {
    avatarUpdateToken
  }
}
    `;
export const UpdateSelfDocument = gql`
    mutation updateSelf($commercialSolicitations: Boolean!, $emailNotifications: Boolean!, $preferredLocale: String!) {
  updateSelf(
    commercialSolicitations: $commercialSolicitations
    emailNotifications: $emailNotifications
    preferredLocale: $preferredLocale
  ) {
    id
    commercialSolicitations
    emailNotifications
    preferredLocale
  }
}
    `;
export const CreateOrganizationDocument = gql`
    mutation createOrganization($name: String!, $id: UUID!) {
  createOrganization(name: $name, id: $id) {
    id
    name
  }
}
    `;
export const PopulateEntityInformationDocument = gql`
    mutation populateEntityInformation($organizationId: UUID!, $entityInformation: EntityInformationInput!) {
  populateEntityInformation(
    organizationId: $organizationId
    entityInformation: $entityInformation
  ) {
    ...AllOrganizationData
  }
}
    ${AllOrganizationDataFragmentDoc}`;
export const RemoveMembershipDocument = gql`
    mutation removeMembership($userId: UUID!, $organizationId: UUID!) {
  removeMembership(userId: $userId, organizationId: $organizationId) {
    id
  }
}
    `;
export const AcceptPendingMembershipDocument = gql`
    mutation acceptPendingMembership($id: UUID!) {
  acceptPendingMembership(id: $id) {
    id
  }
}
    `;
export const RejectPendingMembershipDocument = gql`
    mutation rejectPendingMembership($id: UUID!) {
  rejectPendingMembership(id: $id)
}
    `;
export const SignDocumentDocument = gql`
    mutation signDocument($organizationId: UUID!, $signPayload: String!) {
  registerOrganizationSignature(
    organizationId: $organizationId
    signPayload: $signPayload
  ) {
    id
  }
}
    `;
export const CreateOrganizationWithAllDataDocument = gql`
    mutation createOrganizationWithAllData($name: String!, $entityInformation: EntityInformationInput!, $signPayload: String!) {
  createOrganizationWithAllData(
    name: $name
    entityInformation: $entityInformation
    signPayload: $signPayload
  )
}
    `;
export const InviteMemberDocument = gql`
    mutation inviteMember($email: String!, $id: UUID!) {
  inviteMember(email: $email, id: $id) {
    id
    userId
  }
}
    `;
export const AmOrganizationDocument = gql`
    query amOrganization($id: UUID!) {
  orgaForAm(orgId: $id) {
    ...AllOrganizationData
  }
}
    ${AllOrganizationDataFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    fetchMe(variables?: FetchMeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FetchMeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FetchMeQuery>(FetchMeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fetchMe', 'query');
    },
    fetchMeSettings(variables?: FetchMeSettingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FetchMeSettingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FetchMeSettingsQuery>(FetchMeSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fetchMeSettings', 'query');
    },
    fetchOrganization(variables: FetchOrganizationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FetchOrganizationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FetchOrganizationQuery>(FetchOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fetchOrganization', 'query');
    },
    fetchAllOrganizations(variables?: FetchAllOrganizationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FetchAllOrganizationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FetchAllOrganizationsQuery>(FetchAllOrganizationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fetchAllOrganizations', 'query');
    },
    fetchOrganizationSignatureData(variables: FetchOrganizationSignatureDataQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FetchOrganizationSignatureDataQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FetchOrganizationSignatureDataQuery>(FetchOrganizationSignatureDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fetchOrganizationSignatureData', 'query');
    },
    deleteSession(variables: DeleteSessionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteSessionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteSessionMutation>(DeleteSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteSession', 'mutation');
    },
    fetchMasterKey(variables?: FetchMasterKeyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FetchMasterKeyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FetchMasterKeyQuery>(FetchMasterKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fetchMasterKey', 'query');
    },
    fetchSecondaryKey(variables: FetchSecondaryKeyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FetchSecondaryKeyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FetchSecondaryKeyQuery>(FetchSecondaryKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fetchSecondaryKey', 'query');
    },
    updateMasterKey(variables: UpdateMasterKeyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateMasterKeyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMasterKeyMutation>(UpdateMasterKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateMasterKey', 'mutation');
    },
    addSecondaryKey(variables: AddSecondaryKeyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddSecondaryKeyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddSecondaryKeyMutation>(AddSecondaryKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addSecondaryKey', 'mutation');
    },
    fetchOrganizationAvatarUpdateToken(variables: FetchOrganizationAvatarUpdateTokenQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FetchOrganizationAvatarUpdateTokenQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FetchOrganizationAvatarUpdateTokenQuery>(FetchOrganizationAvatarUpdateTokenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fetchOrganizationAvatarUpdateToken', 'query');
    },
    fetchUserAvatarUpdateToken(variables?: FetchUserAvatarUpdateTokenQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FetchUserAvatarUpdateTokenQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FetchUserAvatarUpdateTokenQuery>(FetchUserAvatarUpdateTokenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fetchUserAvatarUpdateToken', 'query');
    },
    updateSelf(variables: UpdateSelfMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateSelfMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateSelfMutation>(UpdateSelfDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSelf', 'mutation');
    },
    createOrganization(variables: CreateOrganizationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateOrganizationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateOrganizationMutation>(CreateOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createOrganization', 'mutation');
    },
    populateEntityInformation(variables: PopulateEntityInformationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PopulateEntityInformationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PopulateEntityInformationMutation>(PopulateEntityInformationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'populateEntityInformation', 'mutation');
    },
    removeMembership(variables: RemoveMembershipMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveMembershipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveMembershipMutation>(RemoveMembershipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeMembership', 'mutation');
    },
    acceptPendingMembership(variables: AcceptPendingMembershipMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AcceptPendingMembershipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AcceptPendingMembershipMutation>(AcceptPendingMembershipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'acceptPendingMembership', 'mutation');
    },
    rejectPendingMembership(variables: RejectPendingMembershipMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RejectPendingMembershipMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RejectPendingMembershipMutation>(RejectPendingMembershipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'rejectPendingMembership', 'mutation');
    },
    signDocument(variables: SignDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SignDocumentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SignDocumentMutation>(SignDocumentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'signDocument', 'mutation');
    },
    createOrganizationWithAllData(variables: CreateOrganizationWithAllDataMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateOrganizationWithAllDataMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateOrganizationWithAllDataMutation>(CreateOrganizationWithAllDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createOrganizationWithAllData', 'mutation');
    },
    inviteMember(variables: InviteMemberMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InviteMemberMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<InviteMemberMutation>(InviteMemberDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'inviteMember', 'mutation');
    },
    amOrganization(variables: AmOrganizationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AmOrganizationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AmOrganizationQuery>(AmOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'amOrganization', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;