import type { Context } from '@nuxt/types'
import { init as crispInit, updateUser as updateUserCrisp } from '~/packages/core-utils/crisp'
import { sessionInfo } from '~/lib/zerauth'

let initialized = false

export default function (context: Context) {
  try {
    if (!initialized) {
      initialized = true
      crispInit()
    }

    if (sessionInfo.me) {
      updateUserCrisp(sessionInfo.me)
      context.$sentry?.setUser({
        id: sessionInfo.me.id,
      })
    }
  } catch {
    /* Ignore everything, we must not crash here for Crisp */
  }
}
